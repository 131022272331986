import axios from "axios"

// const env = process.env.NODE_ENV;
const service = axios.create({
  // baseURL: "http://127.0.0.1:7001", // 设置统一的请求前缀
  baseURL: "https://api.jstyx.com.cn", // 设置统一的请求前缀
  // baseURL: "/openapi",
  timeout: 100000, // 设置统一的超时时长
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "*",
  },
})
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers["authorization"] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(response => {
  const { status, data } = response
  if (status === 200) {
    return data
  } else {
    return {
      code: 10001,
      msg: " 请求失败",
    }
  }
})

export default service
